export default [
  {
    title: 'Tableau de bord',
    route: 'espace-coproprietaire.dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Copropriété',
    icon: 'ListIcon',
    children: [
      {
        title: 'Gestion des biens immobiliers',
        route: 'espace-coproprietaire.proprietes',
        icon: 'DiscIcon',
      }, {
        title: 'Gestion des demandes',
        route: 'espace-coproprietaire.demande-de-logement',
        icon: 'DiscIcon',
      },
      {
        title: 'Configuration des contrats',
        icon: 'DiscIcon',
        children: [
          {
            title: 'Contrats de bail',
            route: 'espace-coproprietaire.contrats',
            color: 'secondary',
            icon: 'DiscIcon',
          },
          {
            title: 'Contrats Achat-vente',
            route: 'espace-coproprietaire.contrats-achat-vente',
            color: 'secondary',
            icon: 'DiscIcon',
          },

        ],
      },
      {
        title: 'Factures',
        icon: 'DiscIcon',
        children: [
          {
            title: 'Facture de loyer',
            route: 'espace-coproprietaire.facture-de-loyer',
            color: 'secondary',
            icon: 'DiscIcon',
          },
          {
            title: 'Frais de copropriété',
            route: 'espace-coproprietaire.frais-de-copropriete',
            color: 'secondary',
            icon: 'DiscIcon',
          },
          {
            title: 'Facture de copropriété',
            route: 'espace-coproprietaire.facture-de-copropriete',
            color: 'secondary',
            icon: 'DiscIcon',
          },

        ],
      },

    ],
  },

]
