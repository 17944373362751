<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        :href="SITE_URL"
        target="_blank"
      >{{ appName }}</b-link>
      <span class="d-none d-sm-inline-block">, Tous droits réservés</span>
    </span>

    <span class="float-md-right d-none d-md-block text-primary">{{ appName }} - Espace Copropriétaire
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
  },
  setup() {
    const { appName, SITE_URL } = $themeConfig.app
    return {
      appName,
      SITE_URL,
    }
  },
}
</script>
